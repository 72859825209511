import { AdvertCurrencyModel } from './Adverts';
import { ArtistPlatformIdModel } from './Artist';

export type MetaAdsFormModel = {
  accountId?: string;
  ad: MetaAdsFormAdModel;
  facebookAdAccount: MetaFormAdAccountModel;
  priceLocale: string;
};

export type MetaAdCurrencyModel = {
  baseDailyBudgetValue: number;
  code: string;
  minDailySpendRatio: number;
  name: string;
  offset: number;
};

export type MetaAdsFormAdModel = {
  content: MetaContentModel;
  budget?: number;
  link?: string;
  goal?: string;
  message?: string;
  currency?: MetaAdCurrencyModel;
  currencyCode?: string;
  callToAction?: string;
  targeting: MetaTargetingModel;
  type?: string;
  media: string[];
  videoThumbnail?: string;
  startTime: string;
  endTime: string;
  platforms: string[];
};

export type MetaContentModel = {
  primaryText: string;
  link: string;
  linkHeadline: string;
  linkDescription: string;
};

export type MetaFormAdAccountModel = {
  adAccountId?: string;
  accessToken?: string;
  facebookPageId?: string;
  instagramPageId?: string;
};

export type MetaTargetingModel = {
  ageMax: number;
  ageMin: number;
  minAge?: number;
  maxAge?: number;
  audiences: MetaTargetingAudiencesModel[];
  genders: number[];
  geoLocations: MetaTargetingGeolocationModel;
};

export type MetaTargetingAudiencesModel = {
  id: string;
  name: string;
  type: string;
};

export type MetaTargetingGeolocationModel = {
  countryGroups: string[];
  countries: string[];
  cities: MetaTergetingGeolocationDataModel[];
  regions: MetaTergetingGeolocationDataModel[];
};

export type MetaTergetingGeolocationDataModel = {
  name: string;
  key: string;
};

export type MetaAdsAccountModel = {
  data: MetaAdsAccountDataModel[];
  paging: MetaAdsPagingModel;
};

export type MetaAdsAccountDataModel = {
  currency: AdvertCurrencyModel;
  account_id: string;
  id: string;
  name: string;
  account_status: number;
  business: MetaAdsBusinessModel;
  agencies: MetaAdsAgenciesModel[];
  funding_source: string;
  offsite_pixels_tos_accepted: boolean;
  owner: string;
};

export type MetaAdsPagingModel = {
  cursors: MetaAdsAccountPagingCursorsModel;
  previous: string;
  next: string;
};

export type MetaAdsAccountPagingCursorsModel = {
  before: string;
  after: string;
};

export type MetaAdsBusinessModel = {
  id: string;
  name: string;
};

export type MetaAdsAgenciesModel = {
  access_status: string;
  id: string;
  name: string;
  permitted_tasks: string[];
};

export type MetaAdsPagesModel = {
  data: MetaAdsPagesDataModel[];
  paging: MetaAdsPagingModel;
};

export type MetaAdsPagesDataModel = {
  access_token: string;
  agencies: MetaAdsAgenciesModel[];
  business: MetaAdsBusinessModel;
  id: string;
  instagram_accounts: MetaAdsInstagramModel[];
  link: string;
  name: string;
  picture: MetaAdsPicturesModel;
};

export type MetaAdsInstagramModel = {
  id: string;
  profile_pic: string;
  username: string;
};

export type MetaAdsPicturesModel = {
  data: MetaAdsPicturesDataModel;
};

export type MetaAdsPicturesDataModel = {
  height: number;
  width: number;
  url: string;
};

export type MetaAdsVerifyModel = {
  issues: MetaAdsVerifyIssuesModel[];
  success: boolean;
};

export type MetaAdsVerifyIssuesModel = {
  severity: number;
  message: string;
  title: string;
  link: MetaAdsVerifyIssuesLinkModel;
};

export type MetaAdsVerifyIssuesLinkModel = {
  articleId: string;
  text: string;
  url: string;
};

export enum PredeterminedLocations {
  NONE = '',
  PRIMARY = 'primary',
  WORLDWIDE = 'world',
}

export type MetaAdsLocationSearchModel = {
  data: MetaAdsLocationModel[];
  paging: MetaAdsPagingModel;
};

export type MetaAdsLocationModel = {
  country_code: string;
  country_name: string;
  key: string;
  name: string;
  region: string;
  type: string;
};

export type MetaAdsLanguagesSearchModel = {
  data: MetaAdsLanguagesSearchDataModel[];
  paging: MetaAdsPagingModel;
};

export type MetaAdsLanguagesSearchDataModel = {
  key: string;
  name: string;
};

export type MetaAdsInterestsModel = {
  audience_size_lower_bound: number;
  audience_size_upper_bound: number;
  id: string;
  name: string;
  path: string[];
  type: string;
};

export type FacebookAdImagesModel = {
  images: FacebookAdImagesImagesModel;
};

export type FacebookAdImagesImagesModel = {
  bytes: FacebookAdImagesBytesModel;
};

export type FacebookAdImagesBytesModel = {
  hash: string;
  url: string;
  url_128: string;
  url_256: string;
  url_256_height: string;
  url_256_width: string;
  height: number;
  width: number;
  name: string;
};

export type MetaAdImagesModel = {
  data: MetaAdImagesDataModel[];
  paging: MetaAdsPagingModel;
};

export type MetaAdImagesDataModel = {
  hash: string;
  url: string;
  id: string;
};

export type MetaAdVideoModel = {
  data: MetaAdVideoDataModel[];
  paging: MetaAdsPagingModel;
};

export type MetaAdVideoDataModel = {
  id: string;
  source: string;
  title: string;
  views: number;
  status: MetaAdVideoDataStatusModel;
  thumbnails: MetaAdVideoDataThumbnailsModel;
  length: number;
  created_time: string;
};

export type MetaAdVideoDataStatusModel = {
  progress: number;
  status: string;
};

export type MetaAdVideoDataThumbnailsModel = {
  data: MetaAdVideoDataThumbnailsDataModel[];
};

export type MetaAdVideoDataThumbnailsDataModel = {
  id: string;
  uri: string;
  is_preferred: boolean;
};

export type MetaCreativeModel = {
  image_url: string;
  ctaText: string;
  video_id: string;
  video_url?: string;
};

export type MetaAdvertModel = {
  platform: string;
  metadata: MetaAdvertMetadataModel;
  summary: Omit<MetaAdsFormAdModel, 'targeting'> &
    MetaTargetingModel & { priceLocale: string; locationTargeting: object };
  accountId: string;
  name: string;
  status: string;
  paid: boolean;
  artistIds: ArtistPlatformIdModel;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type MetaAdvertMetadataModel = {
  facebookCampaignId: string;
  facebookAdSetId: string;
  facebookAdCreativeId: string;
  facebookAdId: string;
  accessToken: string;
};

export type MetaErrorModel = {
  statusCode: number;
  title: string;
  errorMessage: string;
  type: string;
  errors: MetaErrorValidationErrorModel[];
};

export type MetaErrorValidationErrorModel = {
  propertyName: string;
  propertyValue: string;
  errorMessage: string;
};

export type MetaTargetingSuggestionsModel = {
  interests: MetaAdsInterestsModel[];
  locations: MetaAdsLocationModel[];
};
