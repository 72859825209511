import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import clevertap from 'clevertap-web-sdk';

import './i18n';

import { reactPlugin } from './services/ApplicationInsightsService';
import App from './App';

import './index.css';

const APP_ID = import.meta.env.VITE_CLEVERTAP_APP_ID;
clevertap.init(APP_ID);
clevertap.spa = true;

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </React.StrictMode>
  );
});
