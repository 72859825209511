import { DateTimes } from '@/constants/DateTimes';

export const SubscriptionMetricsMock = {
  youtubeTopCountries: [
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      percentageOfTotalViews: 25.19,
      views: 48192,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      percentageOfTotalViews: 18.17,
      views: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      percentageOfTotalViews: 12.17,
      views: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      percentageOfTotalViews: 8.17,
      views: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      percentageOfTotalViews: 6.17,
      views: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      percentageOfTotalViews: 2.17,
      views: 34758,
    },
  ],
  youtubeTopCities: [
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalViews: 6.66,
      views: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'US',
      percentageOfTotalViews: 5.25,
      views: 8132,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalViews: 4.66,
      views: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'US',
      percentageOfTotalViews: 3.25,
      views: 8132,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalViews: 2.66,
      views: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'US',
      percentageOfTotalViews: 1.25,
      views: 8132,
    },
  ],
  spotifyTopCountries: [
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'US',
      percentageOfTotalMonthlyListeners: 25.19,
      monthlyListeners: 48192,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 18.17,
      monthlyListeners: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 12.17,
      monthlyListeners: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 8.17,
      monthlyListeners: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 6.17,
      monthlyListeners: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 2.17,
      monthlyListeners: 34758,
    },
  ],
  spotifyTopCities: [
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 6.66,
      monthlyListeners: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'US',
      percentageOfTotalMonthlyListeners: 5.25,
      monthlyListeners: 8132,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 4.66,
      monthlyListeners: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'US',
      percentageOfTotalMonthlyListeners: 3.25,
      monthlyListeners: 8132,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'GB',
      percentageOfTotalMonthlyListeners: 2.66,
      monthlyListeners: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      date: DateTimes.todayDate,
      countryCode: 'US',
      percentageOfTotalMonthlyListeners: 1.25,
      monthlyListeners: 8132,
    },
  ],
  instagramTopCountries: [
    {
      name: 'This is dummy data, subscribe to get real data',
      code: 'US',
      percent: 25.19,
      followers: 48192,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      code: 'GB',
      percent: 18.17,
      followers: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      code: 'GB',
      percent: 12.17,
      followers: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      code: 'GB',
      percent: 8.17,
      followers: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      code: 'GB',
      percent: 6.17,
      followers: 34758,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      code: 'GB',
      percent: 2.17,
      followers: 34758,
    },
  ],
  instagramTopCities: [
    {
      name: 'This is dummy data, subscribe to get real data',
      country: 'United Kingdom',
      percent: 6.66,
      followers: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      country: 'United States',
      percent: 5.25,
      followers: 8132,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      country: 'United Kingdom',
      percent: 4.66,
      followers: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      country: 'United States',
      percent: 3.25,
      followers: 8132,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      country: 'United Kingdom',
      percent: 2.66,
      followers: 12740,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      country: 'United States',
      percent: 1.25,
      followers: 8132,
    },
  ],
  gendersPerAgeGroup: [
    {
      code: '13-17',
      male: 2.25,
      female: 3.29,
    },
    {
      code: '18-24',
      male: 19.8,
      female: 19.13,
    },
    {
      code: '25-34',
      male: 25.34,
      female: 19.4,
    },
    {
      code: '35-44',
      male: 6.29,
      female: 2.82,
    },
    {
      code: '45-64',
      male: 1.1,
      female: 0.57,
    },
    {
      code: '65-',
      male: 0,
      female: 0.01,
    },
  ],
  genders: [
    {
      code: 'male',
      weight: 54.78,
    },
    {
      code: 'female',
      weight: 45.22,
    },
  ],
  brandAffinities: [
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 3.88,
      affinity: 3.171968709,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 9.23,
      affinity: 2.760438122,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 3.88,
      affinity: 3.171968709,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 9.23,
      affinity: 2.760438122,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 3.88,
      affinity: 3.171968709,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 9.23,
      affinity: 2.760438122,
    },
  ],
  interestAffinities: [
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 9.23,
      affinity: 2.760438122,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 3.88,
      affinity: 3.171968709,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 9.23,
      affinity: 2.760438122,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 3.88,
      affinity: 3.171968709,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 9.23,
      affinity: 2.760438122,
    },
    {
      name: 'This is dummy data, subscribe to get real data',
      weight: 9.23,
      affinity: 2.760438122,
    },
  ],
  notableFollowers: [
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYvy61sBcp4g%2BGwAUOgcFcW%2F1YvwoY1nrczO8EH2EW3PVgZbv5OpDliIOvahP%2ByNO1sI3J1Oadjn75GpV%2B5rlSmWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D',
      followers: 73272196,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      followers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYvy61sBcp4g%2BGwAUOgcFcW%2F1YvwoY1nrczO8EH2EW3PVgZbv5OpDliIOvahP%2ByNO1sI3J1Oadjn75GpV%2B5rlSmWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D',
      followers: 73272196,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      followers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      followers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      followers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      followers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
  ],
  notableSubscribers: [
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYvy61sBcp4g%2BGwAUOgcFcW%2F1YvwoY1nrczO8EH2EW3PVgZbv5OpDliIOvahP%2ByNO1sI3J1Oadjn75GpV%2B5rlSmWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D',
      subscribers: 73272196,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      subscribers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYvy61sBcp4g%2BGwAUOgcFcW%2F1YvwoY1nrczO8EH2EW3PVgZbv5OpDliIOvahP%2ByNO1sI3J1Oadjn75GpV%2B5rlSmWwq5%2BhsabX0Nc9aXCBbcsw%3D%3D',
      subscribers: 73272196,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      subscribers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      subscribers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      subscribers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
    {
      picture:
        'https://imgp.sptds.icu/v2?mb0KwpL92uYofJiSjDn1%2F6peL1lBwv3s%2BUvShHERlDYwCkfbMxA5hs7Mya6WamPUBXQFgFzt%2Fr191A5KCliiv8ryQSSI504VqG1o5GxHLFX1TzNGEqWkFeClZp8kXMk8',
      subscribers: 56034411,
      fullName: 'Dummy follower, subscribe to get real data',
    },
  ],
};

export const SubscriptionPlaylistsMock = new Array(4).fill(null).map((_, index) => ({
  playlist: {
    addedAt: '2024-02-06T00:00:00.000Z',
    coverImageUrl:
      'https://is2-ssl.mzstatic.com/image/thumb/Features118/v4/34/93/05/3493059a-7a7b-c426-a4cf-b8157f9ed15c/source/160x160cc.jpeg',
    followers: 0,
    id: 169480,
    name: 'This is a dummy playlist, subscribe to get real data',
    numberOfTracks: 50,
    spotifyId: `spotify:playlist:9ae57b1e018f4fac8982ed457287da7c-${index}`,
  },
  track: {
    coverImageUrl: 'https://i.scdn.co/image/ab67616d0000b273c2561979d8c83d4479a2c219',
    id: 62184687 + index,
    name: 'This is a dummy track, subscribe to get real data',
  },
}));

export const SubscriptionArtistsMock = new Array(4).fill(null).map((_, index) => ({
  externalUrls: {
    spotify: 'https://open.spotify.com/artist/5WUlDfRSoLAfcVSX1WnrxN',
  },
  followers: {
    total: 27399517,
  },
  genres: ['australian dance', 'australian pop', 'pop'],
  href: '',
  id: `5WUlDfRSoLAfcVSX1WnrxN-${index}`,
  images: [
    {
      height: 640,
      width: 640,
      url: 'https://i.scdn.co/image/ab6761610000e5eb25a749000611559f1719cc5f',
    },
    {
      height: 320,
      width: 320,
      url: 'https://i.scdn.co/image/ab6761610000517425a749000611559f1719cc5f',
    },
    {
      height: 160,
      width: 160,
      url: 'https://i.scdn.co/image/ab6761610000f17825a749000611559f1719cc5f',
    },
  ],
  name: 'Dummy data, subscribe to get real data',
  popularity: 84,
}));

export const SubscriptionTracksMock = new Array(4).fill(null).map((_, index) => ({
  album: {
    id: `5duyQokC4FMcWPYTV9Gpf9-${index}`,
    album_type: 'album',
    total_tracks: 11,
    available_markets: [],
    external_urls: {},
    href: `https://api.spotify.com/v1/albums/5duyQokC4FMcWPYTV9Gpf9-${index}`,
    images: [
      {
        height: 640,
        width: 640,
        url: 'https://i.scdn.co/image/ab67616d0000b273164feb363334f93b6458d2a9',
      },
      {
        height: 300,
        width: 300,
        url: 'https://i.scdn.co/image/ab67616d00001e02164feb363334f93b6458d2a9',
      },
      {
        height: 64,
        width: 64,
        url: 'https://i.scdn.co/image/ab67616d00004851164feb363334f93b6458d2a9',
      },
    ],
    name: '21',
    release_date: '2011-01-24',
    release_date_precision: 'day',
    restrictions: undefined,
    type: 'album',
    uri: `spotify:album:5duyQokC4FMcWPYTV9Gpf9-${index}`,
    artists: [],
    album_group: '',
    tracks: [],
  },
  artists: [
    {
      externalUrls: {},
      followers: {
        total: 0,
      },
      genres: [],
      href: `https://api.spotify.com/v1/artists/4dpARuHxo51G3z768sgnrY-${index}`,
      id: `4dpARuHxo51G3z768sgnrY-${index}`,
      images: [],
      name: 'Adele',
      popularity: 100,
    },
  ],
  duration_ms: 242973,
  external_urls: {
    spotify: `https://open.spotify.com/track/3di5hcvxxciiqwMH1jarhY-${index}`,
  },
  href: `https://api.spotify.com/v1/tracks/3di5hcvxxciiqwMH1jarhY-${index}`,
  id: `3di5hcvxxciiqwMH1jarhY-${index}`,
  is_playable: true,
  name: 'This is a dummy track, subscribe to get real data',
  popularity: 87,
  preview_url: `https://p.scdn.co/mp3-preview/6fc68c105e091645376471727960d2ba3cd0ee01?cid=b3c14fe3d4c14ca6a11d8114ee31ce54-${index}`,
  uri: `spotify:track:3di5hcvxxciiqwMH1jarhY-${index}`,
}));
