export enum InsightsTabs {
  SOCIALS = 'socials',
  MUSIC = 'music',
}

export enum SocialPlatform {
  OVERVIEW = 'overview',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  TWITTER = 'twitter',
}

export enum MusicPlatform {
  OVERVIEW = 'overview',
  SPOTIFY = 'spotify',
  YOUTUBE = 'youtube',
  AMAZON = 'amazon',
  APPLE = 'apple',
  SOUNDCLOUD = 'soundcloud',
  DEEZER = 'deezer',
}
